import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_prodev/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

export function MainRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const UserPage = lazy(() => import('../modules/users/UserPage'))
  const ProductPage = lazy(() => import('../modules/products/ProductPage'))
  const GlobalProductPage = lazy(() => import('../modules/global-products/GlobalProductPage'))
  const CityPage = lazy(() => import('../modules/cities/CityPage'))
  const ProductTypePage = lazy(() => import('../modules/product-types/ProductTypePage'))
  const DistrictPage = lazy(() => import('../modules/districts/DistrictPage'))
  const BazaarTypePage = lazy(() => import('../modules/bazaar-types/BazaarTypePage'))
  const BazaarPage = lazy(() => import('../modules/bazaars/BazaarPage'))
  const DimensionPage = lazy(() => import('../modules/dimensions/DimensionPage'))
  const CategoryPage = lazy(() => import('../modules/categories/CategoryPage'))
  const FeedbackPage = lazy(() => import('../modules/feedbacks/FeedbackPage'))
  const SubscriberPage = lazy(() => import('../modules/subscribers/SubscriberPage'))
  const PaymentPage = lazy(() => import('../modules/payments/PaymentPage'))
  const AgentPage = lazy(() => import('../modules/agents/AgentPage'))
  const DownloadFilePage = lazy(() => import('../modules/download-files/DownloadFilePage'))
  const FileCategoryPage = lazy(() => import('../modules/file-categories/FileCategoryPage'))
  const ProductPricePage = lazy(() => import('../modules/product-prices/ProductPricePage'))
  const AdvertisementPage = lazy(() => import('../modules/advertisements/AdvertisementPage'))
  const DeliveryPage = lazy(() => import('../modules/deliveries/DeliveryPage'))
  const SettingPage = lazy(() => import('../modules/settings/SettingPage'))
  const PostPage = lazy(() => import('../modules/posts/PostPage'))
  const GalleryPage = lazy(() => import('../modules/galleries/GalleryPage'))
  const PagePage = lazy(() => import('../modules/pages/PagePage'))
  const ImagePage = lazy(() => import('../modules/images/ImagePage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path={'/dashboard'} component={DashboardWrapper} />
        <Route path={'/builder'} component={BuilderPageWrapper} />
        <Route path={'/user'} component={UserPage} />
        <Route path={'/product'} component={ProductPage} />
        <Route path={'/global-product'} component={GlobalProductPage} />
        <Route path={'/city'} component={CityPage} />
        <Route path={'/district'} component={DistrictPage} />
        <Route path={'/bazaar-type'} component={BazaarTypePage} />
        <Route path={'/bazaar'} component={BazaarPage} />
        <Route path={'/dimension'} component={DimensionPage} />
        <Route path={'/category'} component={CategoryPage} />
        <Route path={'/feedback'} component={FeedbackPage} />
        <Route path={'/subscriber'} component={SubscriberPage} />
        <Route path={'/payment'} component={PaymentPage} />
        <Route path={'/agent'} component={AgentPage} />
        <Route path={'/download-file'} component={DownloadFilePage} />
        <Route path={'/product-type'} component={ProductTypePage} />
        <Route path={'/file-category'} component={FileCategoryPage} />
        <Route path={'/product-price'} component={ProductPricePage} />
        <Route path={'/advertisement'} component={AdvertisementPage} />
        <Route path={'/delivery'} component={DeliveryPage} />
        <Route path={'/setting'} component={SettingPage} />
        <Route path={'/gallery'} component={GalleryPage} />
        <Route path='/post' component={PostPage} />
        <Route path='/page' component={PagePage} />
        <Route path='/image' component={ImagePage} />
        <Redirect from='/auth' to={'/dashboard'} />
        <Redirect exact from={'/'} to={'/dashboard'} />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
