/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";
import { Me } from "../../../helpers/UserHelpers";

interface MenuItem {
  icon: string
  link: MenuLink
  child: Array<MenuLink>
}

interface MenuLink {
  to: string
  title: string
}

export function AsideMenuMain() {
  const user = Me()
  const intl = useIntl()

  const [userItems, setUserItems] = useState<Array<MenuItem>>([])
  const [appItems, setAppItems] = useState<Array<MenuItem>>([])
  const [systemItems, setSystemItems] = useState<Array<MenuItem>>([])

  const loadData = () => {
    // account items
    const userItem = {
      icon: 'communication/com006.svg',
      link: { to: 'user/list/', title: 'USERS' } as MenuLink,
      child: [
        { to: 'user/list', title: 'LIST' } as MenuLink,
        { to: 'user/add', title: 'ADD' } as MenuLink,
      ]
    } as MenuItem
    const agentItem = {
      icon: 'communication/com020.svg',
      link: { to: 'agent/list/', title: 'AGENTS' } as MenuLink,
      child: [
        { to: 'agent/list', title: 'LIST' } as MenuLink,
        { to: 'agent/add', title: 'ADD' } as MenuLink,
      ]
    } as MenuItem
    const subscriberItem = {
      icon: 'communication/com017.svg',
      link: { to: 'subscriber/list/', title: 'SUBSCRIBERS' } as MenuLink,
      child: [
        { to: 'subscriber/list', title: 'LIST' } as MenuLink,
        { to: 'subscriber/add', title: 'ADD' } as MenuLink,
      ]
    } as MenuItem
    const postItem = {
      icon: 'general/gen005.svg',
      link: { to: 'post/list/', title: 'POSTS' } as MenuLink,
      child: [
        { to: 'post/list', title: 'LIST' } as MenuLink,
        { to: 'post/add', title: 'ADD' } as MenuLink,
      ]
    } as MenuItem
    const pageItem = {
      icon: 'general/gen005.svg',
      link: { to: 'page/list/', title: 'PAGES' } as MenuLink,
      child: [
        { to: 'page/list', title: 'LIST' } as MenuLink,
        { to: 'page/add', title: 'ADD' } as MenuLink,
      ]
    } as MenuItem
    const galleryItem = {
      icon: 'general/gen006.svg',
      link: { to: 'gallery/list/', title: 'GALLERY' } as MenuLink,
      child: [
        { to: 'gallery/list', title: 'LIST' } as MenuLink,
        { to: 'gallery/add', title: 'ADD' } as MenuLink,
      ]
    } as MenuItem
    const imageItem = {
      icon: 'general/gen006.svg',
      link: { to: 'image/list/', title: 'IMAGES' } as MenuLink,
      child: [
        { to: 'image/list', title: 'LIST' } as MenuLink,
        { to: 'image/add', title: 'ADD' } as MenuLink,
      ]
    } as MenuItem
    const cityItem = {
      icon: 'location/loc001.svg',
      link: { to: 'city/list/', title: 'CITIES' } as MenuLink,
      child: [
        { to: 'city/list', title: 'LIST' } as MenuLink,
        { to: 'city/add', title: 'ADD' } as MenuLink,
      ]
    } as MenuItem
    const district = {
      icon: 'location/loc002.svg',
      link: { to: 'district/list/', title: 'DISTRICTS' } as MenuLink,
      child: [
        { to: 'district/list', title: 'LIST' } as MenuLink,
        { to: 'district/add', title: 'ADD' } as MenuLink,
      ]
    } as MenuItem
    const bazaarTypeItem = {
      icon: 'ecommerce/ecm019.svg',
      link: { to: 'bazaar-type/list/', title: 'BAZAAR_TYPES' } as MenuLink,
      child: [
        { to: 'bazaar-type/list', title: 'LIST' } as MenuLink,
        { to: 'bazaar-type/add', title: 'ADD' } as MenuLink,
      ]
    } as MenuItem
    const productPropertyItem = {
      icon: 'art/art002.svg',
      link: { to: 'product-type/list/', title: 'PRODUCT_TYPES' } as MenuLink,
      child: [
        { to: 'product-type/list', title: 'LIST' } as MenuLink,
        { to: 'product-type/add', title: 'ADD' } as MenuLink,
      ]
    } as MenuItem
    const bazaarItem = {
      icon: 'ecommerce/ecm001.svg',
      link: { to: 'bazaar/list/', title: 'BAZAARS' } as MenuLink,
      child: [
        { to: 'bazaar/list', title: 'LIST' } as MenuLink,
        { to: 'bazaar/add', title: 'ADD' } as MenuLink,
      ]
    } as MenuItem
    const dimensionItem = {
      icon: 'general/gen058.svg',
      link: { to: 'dimension/list/', title: 'DIMENSION' } as MenuLink,
      child: [
        { to: 'dimension/list', title: 'LIST' } as MenuLink,
        { to: 'dimension/add', title: 'ADD' } as MenuLink,
      ]
    } as MenuItem
    const categoryItem = {
      icon: 'abstract/abs027.svg',
      link: { to: 'category/list/', title: 'CATEGORIES' } as MenuLink,
      child: [
        { to: 'category/list', title: 'LIST' } as MenuLink,
        { to: 'category/add', title: 'ADD' } as MenuLink,
      ]
    } as MenuItem
    const globalProductItem = {
      icon: 'ecommerce/ecm012.svg',
      link: { to: 'global-product/list/', title: 'GLOBAL_PRODUCTS' } as MenuLink,
      child: [
        { to: 'global-product/list', title: 'LIST' } as MenuLink,
        { to: 'global-product/add', title: 'ADD' } as MenuLink,
      ]
    } as MenuItem
    const productItem = {
      icon: 'ecommerce/ecm012.svg',
      link: { to: 'product/list/', title: 'PRODUCTS' } as MenuLink,
      child: [
        { to: 'product/list', title: 'LIST' } as MenuLink,
        { to: 'product/add', title: 'ADD' } as MenuLink,
      ]
    } as MenuItem
    const productPriceItem = {
      icon: 'finance/fin010.svg',
      link: { to: 'product-price/list/', title: 'PRODUCT_PRICES' } as MenuLink,
      child: [
        { to: 'product-price/list', title: 'LIST' } as MenuLink,
      ]
    } as MenuItem
    const feedbackItem = {
      icon: 'general/gen016.svg',
      link: { to: 'feedback/list/', title: 'FEEDBACKS' } as MenuLink,
      child: [
        { to: 'feedback/list', title: 'LIST' } as MenuLink,
        { to: 'feedback/add', title: 'ADD' } as MenuLink,
      ]
    } as MenuItem
    const fileCategoryItem = {
      icon: 'finance/fin003.svg',
      link: { to: 'file-category/list/', title: 'FILE_CATEGORIES' } as MenuLink,
      child: [
        { to: 'file-category/list', title: 'LIST' } as MenuLink,
        { to: 'file-category/add', title: 'ADD' } as MenuLink,
      ]
    } as MenuItem
    const paymentItem = {
      icon: 'finance/fin002.svg',
      link: { to: 'payment/list/', title: 'PAYMENTS' } as MenuLink,
      child: [
        { to: 'payment/list', title: 'LIST' } as MenuLink,
        { to: 'payment/add', title: 'ADD' } as MenuLink,
      ]
    } as MenuItem
    const advertisementItem = {
      icon: 'communication/com007.svg',
      link: { to: 'advertisement/list/', title: 'ADVERTISEMENTS' } as MenuLink,
      child: [
        { to: 'advertisement/list', title: 'LIST' } as MenuLink,
        { to: 'advertisement/add', title: 'ADD' } as MenuLink,
      ]
    } as MenuItem
    const deliveryItem = {
      icon: 'ecommerce/ecm006.svg',
      link: { to: 'delivery/list/', title: 'DELIVERIES' } as MenuLink,
      child: [
        { to: 'delivery/list', title: 'LIST' } as MenuLink,
        { to: 'delivery/add', title: 'ADD' } as MenuLink,
      ]
    } as MenuItem
    const downloadFileItem = {
      icon: 'files/fil009.svg',
      link: { to: 'download-file/list/', title: 'DOWNLOAD_FILES' } as MenuLink,
      child: [
        { to: 'download-file/list', title: 'LIST' } as MenuLink,
        { to: 'download-file/add', title: 'ADD' } as MenuLink,
      ]
    } as MenuItem
    const settingItem = {
      icon: 'general/gen019.svg',
      link: { to: 'setting/list/', title: 'SETTINGS' } as MenuLink,
      child: [
        { to: 'setting/image', title: 'IMAGE' } as MenuLink,
        { to: 'setting/list', title: 'LIST' } as MenuLink,
        { to: 'setting/add', title: 'ADD' } as MenuLink,
      ]
    } as MenuItem

    if (user.status === 'agent') {
      productPriceItem.child.push({ to: 'product-price/add', title: 'ADD' } as MenuLink,)
    }

    switch (user.status) {
      case 'admin':
        setUserItems([
          userItem,
          agentItem,
          subscriberItem,
        ])
        setAppItems([
          categoryItem,
          postItem,
          pageItem,
          imageItem,
          cityItem,
          district,
          bazaarTypeItem,
          productPropertyItem,
          bazaarItem,
          dimensionItem,
          globalProductItem,
          productItem,
          productPriceItem,
          feedbackItem,
          deliveryItem,
          advertisementItem,
          fileCategoryItem,
          paymentItem,
          downloadFileItem,
        ])
        setSystemItems([
          settingItem,
        ])
        break
      case 'moderator':
        setAppItems([
          productItem,
        ])
        break
      case 'agent':
        setAppItems([
          productPriceItem,
        ])
        break
      case 'subscriber':
        setAppItems([
          productItem,
          downloadFileItem,
        ])
        break
    }

  }

  // eslint-disable-next-line
  useEffect(()=>loadData(), [user.status])

  return (
    <>
      <AsideMenuItem
        to={`/dashboard`}
        icon='/media/icons/duotune/general/gen001.svg'
        title={intl.formatMessage({id: 'DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* begin::UserItems */}
      {userItems.length > 0 ? <div className="item mx-2">
        <div className="content pt-8 pb-2">
          <span className="section text-muted text-uppercase fs-8 ls-1">
            {intl.formatMessage({ id: "ACCOUNT" })}
          </span>
        </div>
      </div> : null}
      {(userItems ?? []).map((e, i)=>{
        return <AsideMenuItemWithSub
          key={`item-${i}`}
          to={`/${e.link.to}`}
          title={intl.formatMessage({id: e.link.title.toUpperCase()})}
          icon={`/media/icons/duotune/${e.icon}`}
          fontIcon='bi-person'
        >
          {(e.child ?? []).map((ce, ci)=>{
            return <AsideMenuItem
              key={`child-${ci}`}
              to={`/${ce.to}`}
              title={intl.formatMessage({id: ce.title.toUpperCase()})} hasBullet={true}
            />
          })}
        </AsideMenuItemWithSub>
      })}
      {/* end::AppsItems */}
      {/* begin::AppsItems */}
      {appItems.length > 0? <div className='item mx-2'>
        <div className='content pt-8 pb-2'>
          <span className='section text-muted text-uppercase fs-8 ls-1'>
            {intl.formatMessage({id: 'APPS'})}
          </span>
        </div>
      </div> : null}
      {(appItems ?? []).map((e, i)=>{
        return <AsideMenuItemWithSub
          key={`item-${i}`}
          to={`/${e.link.to}`}
          title={intl.formatMessage({id: e.link.title.toUpperCase()})}
          icon={`/media/icons/duotune/${e.icon}`}
          fontIcon='bi-person'
        >
          {(e.child ?? []).map((ce, ci)=>{
            return <AsideMenuItem
              key={`child-${ci}`}
              to={`/${ce.to}`}
              title={intl.formatMessage({id: ce.title.toUpperCase()})} hasBullet={true}
            />
          })}
        </AsideMenuItemWithSub>
      })}
      <div className='item'>
        <div className='content'>
          <div className='separator mx-1 my-1'></div>
        </div>
      </div>
      {/* end::AppsItems */}
      {/* begin::SystemItems */}
      {systemItems.length > 0? <div className='item mx-2'>
        <div className='content pt-0 pb-2'>
          <span className='section text-muted text-uppercase fs-8 ls-1'>
            {intl.formatMessage({id: 'SYSTEM'})}
          </span>
        </div>
      </div> : null}
      {(systemItems ?? []).map((e, i)=>{
        return <AsideMenuItemWithSub
          key={`item-${i}`}
          to={`/${e.link.to}`}
          title={intl.formatMessage({id: e.link.title.toUpperCase()})}
          icon={`/media/icons/duotune/${e.icon}`}
          fontIcon='bi-person'
        >
          {(e.child ?? []).map((ce, ci)=>{
            return <AsideMenuItem
              key={`child-${ci}`}
              to={`/${ce.to}`}
              title={intl.formatMessage({id: ce.title.toUpperCase()})} hasBullet={true}
            />
          })}
        </AsideMenuItemWithSub>
      })}
      <div className='item'>
        <div className='content'>
          <div className='separator mx-1 my-1'></div>
        </div>
      </div>
      {/* end::SystemItems */}
    </>
  )
}
